import { TYPE_DISMISS_DIALOG } from 'data/dialog';
import * as insightsTypes from 'data/insights';
import * as labelsTypes from 'data/labels';
import * as pledgesTypes from 'data/pledges';
import * as surveysTypes from 'data/surveys';
import * as tracksTypes from 'data/tracks';
import { TYPE_RECEIVE_FORMATTERS_ERROR } from 'data/formatters';

const initState = [];

const insightReducer = action => {
  switch (action.type) {
    case insightsTypes.TYPE_RECEIVE_INSIGHTS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch insights.' };
    case insightsTypes.TYPE_RECEIVE_INSIGHT_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch insight.' };
    case insightsTypes.TYPE_CREATE_INSIGHT_SUCCESS:
      return { variant: 'success', message: `Successfully created ${action.insight.name}` };
    case insightsTypes.TYPE_CREATE_INSIGHT_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem creating the insight.'
      };
    case insightsTypes.TYPE_UPDATE_INSIGHT_SUCCESS:
      return { variant: 'success', message: `Successfully updated ${action.insight.name}` };
    case insightsTypes.TYPE_UPDATE_INSIGHT_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem updating the insight.'
      };
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST:
      return { variant: 'error', message: 'Success! All tests in Labels passed.' };
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST_ERROR:
      return { variant: 'error', message: action.error };
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};

const labelReducer = action => {
  switch (action.type) {
    case labelsTypes.TYPE_RECEIVE_LABELS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch labels.' };
    case labelsTypes.TYPE_RECEIVE_LABEL_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch label.' };
    case labelsTypes.TYPE_UPDATE_LABEL_SUCCESS:
      return { variant: 'success', message: `Successfully updated label ${action.label.id}.` };
    case labelsTypes.TYPE_UPDATE_LABEL_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem updating the label.'
      };
    case labelsTypes.TYPE_CREATE_LABEL_SUCCESS:
      return { variant: 'success', message: `Successfully created label ${action.label.id}.` };
    case labelsTypes.TYPE_CREATE_LABEL_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem creating the label.'
      };
    case labelsTypes.TYPE_RECEIVE_CLUSTERS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch clusters.' };
    case labelsTypes.TYPE_CREATE_CLUSTER_SUCCESS:
      return { variant: 'success', message: `Successfully created cluster ${action.clusterName}.` };
    case labelsTypes.TYPE_CREATE_CLUSTER_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem creating the cluster.'
      };
    default:
      return;
  }
};

const pledgesReducer = action => {
  switch (action.type) {
    case pledgesTypes.TYPE_RECEIVE_PLEDGES_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch pledges.' };
    case pledgesTypes.TYPE_RECEIVE_PLEDGE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch pledge.' };
    case pledgesTypes.TYPE_UPDATE_PLEDGE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update pledge.' };
    case pledgesTypes.TYPE_UPDATE_PLEDGE_SUCCESS:
      return { variant: 'success', message: `Successfully updated pledge ${action.pledge.id}.` };
    case pledgesTypes.TYPE_CREATE_PLEDGE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create pledge.' };
    case pledgesTypes.TYPE_CREATE_PLEDGE_SUCCESS:
      return { variant: 'success', message: `Successfully created pledge ${action.pledge.id}.` };
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};

const surveysReducer = action => {
  switch (action.type) {
    case surveysTypes.TYPE_RECEIVE_SURVEYS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch surveys.' };
    case surveysTypes.TYPE_RECEIVE_SURVEY_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch survey.' };
    case surveysTypes.TYPE_UPDATE_SURVEY_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update survey.' };
    case surveysTypes.TYPE_UPDATE_SURVEY_SUCCESS:
      return { variant: 'success', message: `Successfully updated survey ${action.survey.id}.` };
    case surveysTypes.TYPE_CREATE_SURVEY_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create survey.' };
    case surveysTypes.TYPE_CREATE_SURVEY_SUCCESS:
      return { variant: 'success', message: `Successfully created survey ${action.survey.id}.` };
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};

const tracksReducer = action => {
  switch (action.type) {
    case tracksTypes.TYPE_RECEIVE_TRACKS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch tracks.' };
    case tracksTypes.TYPE_RECEIVE_TRACK_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch track.' };
    case tracksTypes.TYPE_UPDATE_TRACK_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update track.' };
    case tracksTypes.TYPE_UPDATE_TRACK_SUCCESS:
      return { variant: 'success', message: `Successfully updated track ${action.track.id}.` };
    case tracksTypes.TYPE_CREATE_TRACK_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create track.' };
    case tracksTypes.TYPE_CREATE_TRACK_SUCCESS:
      return { variant: 'success', message: `Successfully created track ${action.track.id}.` };
    default:
      return;
  }
};

/**
 * Sub reducer to build a message from a formatters action
 */
function formatters(action) {
  if (action.type === TYPE_RECEIVE_FORMATTERS_ERROR) {
    return { variant: 'error', message: action.error || 'Unable to fetch formatters.' };
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case TYPE_DISMISS_DIALOG:
      return state.slice(1);
    case insightsTypes.TYPE_UPDATE_INSIGHT_SUCCESS:
    case insightsTypes.TYPE_UPDATE_INSIGHT_ERROR:
    case insightsTypes.TYPE_CREATE_INSIGHT_SUCCESS:
    case insightsTypes.TYPE_CREATE_INSIGHT_ERROR:
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST:
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST_ERROR:
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_SUCCESS:
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_ERROR:
      return [...state, insightReducer(action)];
    case labelsTypes.TYPE_RECEIVE_LABELS_ERROR:
    case labelsTypes.TYPE_UPDATE_LABEL_SUCCESS:
    case labelsTypes.TYPE_UPDATE_LABEL_ERROR:
    case labelsTypes.TYPE_CREATE_LABEL_SUCCESS:
    case labelsTypes.TYPE_CREATE_LABEL_ERROR:
    case labelsTypes.TYPE_RECEIVE_CLUSTERS_ERROR:
    case labelsTypes.TYPE_CREATE_CLUSTER_SUCCESS:
    case labelsTypes.TYPE_CREATE_CLUSTER_ERROR:
      return [...state, labelReducer(action)];
    case pledgesTypes.TYPE_CREATE_PLEDGE_SUCCESS:
    case pledgesTypes.TYPE_CREATE_PLEDGE_ERROR:
    case pledgesTypes.TYPE_UPDATE_PLEDGE_SUCCESS:
    case pledgesTypes.TYPE_UPDATE_PLEDGE_ERROR:
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_SUCCESS:
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_ERROR:
      return [...state, pledgesReducer(action)];
    case surveysTypes.TYPE_RECEIVE_SURVEYS_ERROR:
    case surveysTypes.TYPE_RECEIVE_SURVEY_ERROR:
    case surveysTypes.TYPE_UPDATE_SURVEY_SUCCESS:
    case surveysTypes.TYPE_UPDATE_SURVEY_ERROR:
    case surveysTypes.TYPE_CREATE_SURVEY_SUCCESS:
    case surveysTypes.TYPE_CREATE_SURVEY_ERROR:
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_SUCCESS:
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_ERROR:
      return [...state, surveysReducer(action)];
    case tracksTypes.TYPE_RECEIVE_TRACKS_ERROR:
    case tracksTypes.TYPE_RECEIVE_TRACK_ERROR:
    case tracksTypes.TYPE_UPDATE_TRACK_SUCCESS:
    case tracksTypes.TYPE_UPDATE_TRACK_ERROR:
    case tracksTypes.TYPE_CREATE_TRACK_SUCCESS:
    case tracksTypes.TYPE_CREATE_TRACK_ERROR:
      return [...state, tracksReducer(action)];
    case TYPE_RECEIVE_FORMATTERS_ERROR:
      return [...state, formatters(action)];
    default:
      return state;
  }
};
