import { authorizedAxios } from 'config/axios';
import TrackFactory from 'factories/tracks';

const FACTUARY_URL = '/factuary/api/v1';

export const fetchTracks = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/tracks`).then(response => ({
    ...response,
    tracks: response.tracks.map(TrackFactory.toModel)
  }));
};

export const createTrack = track => {
  return authorizedAxios.post(`${FACTUARY_URL}/tracks`, TrackFactory.toDTO(track));
};

export const updateTrack = track => {
  return authorizedAxios.put(`${FACTUARY_URL}/tracks/${track.id}`, TrackFactory.toDTO(track));
};

export const fetchTrack = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/tracks/${id}`).then(response => ({
    ...response,
    track: TrackFactory.toModel(response.track)
  }));
};
