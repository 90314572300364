import { authorizedAxios } from 'config/axios';
import SurveyFactory from 'factories/surveys';

const FACTUARY_URL = '/factuary/api/v1';

export const fetchSurveys = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/surveys`).then(response => ({
    ...response,
    surveys: response.surveys.map(SurveyFactory.toModel),
  }));
};

export const createSurvey = survey => {
  return authorizedAxios.post(`${FACTUARY_URL}/surveys`, SurveyFactory.toDTO(survey));
};

export const updateSurvey = survey => {
  return authorizedAxios.put(`${FACTUARY_URL}/surveys/${survey.id}`, SurveyFactory.toDTO(survey));
};

export const fetchSurvey = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/surveys/${id}`).then(response => ({
    ...response,
    survey: SurveyFactory.toModel(response.survey),
  }));
};

export const getLanguageFile = (surveyId, languageCode) => {
  return authorizedAxios.get(`/factuary/api/v2/strings/survey/csv?content_id=${surveyId}&lang=${languageCode}`);
};

export const getLanguageFileTemplate = (surveyId) => {
  return authorizedAxios.get(`/factuary/api/v2/strings/survey/csv?content_id=${surveyId}`)
    .then((response) => response)
    .catch(err => '');
};


export const postLanguageFile = (languageFile, surveyId, languageCode) => {
  return authorizedAxios.post(`/factuary/api/v2/strings/survey/csv?content_id=${surveyId}&lang=${languageCode}`, languageFile);
};
