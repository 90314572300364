import React from 'react';
import capitalize from 'lodash/capitalize';
import { Chip, FormControl, InputLabel, Select as MUISelect, MenuItem } from '@material-ui/core';

import useStyles from './Select.styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Select = ({
  fullWidth,
  id,
  label,
  margin = 'normal',
  onChange,
  options,
  value,
  required,
  multiple = false,
  ...restProps
}) => {
  const classes = useStyles();
  const handleChange = e => {
    if (onChange) onChange(e);
  };

  const renderOption = option => {
    const Component = multiple ? MenuItem : 'option';
    return (
      <Component key={option.value} value={option.value} disabled={!!option.disabled}>
        {option.text}
      </Component>
    );
  };

  return (
    <FormControl required={required} fullWidth={fullWidth} margin={margin}>
      <InputLabel htmlFor={id}>{capitalize(label)}</InputLabel>
      <MUISelect
        value={value || ''}
        onChange={handleChange}
        inputProps={{
          name: id,
          id: id
        }}
        fullWidth={fullWidth}
        required={required}
        multiple={multiple}
        {...(multiple && {
          renderValue: selected => {
            const selectedOptions = options.filter(option => selected.indexOf(option.value) !== -1);
            return (
              <div className={classes.chips}>
                {selectedOptions.map(option => (
                  <Chip key={option.value} label={option.text} className={classes.chip} />
                ))}
              </div>
            );
          }
        })}
        native={!multiple}
        MenuProps={MenuProps}
        {...restProps}
      >
        {!multiple && <option value="" />}
        {options.map(renderOption)}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
