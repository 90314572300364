import { authorizedAxios } from 'config/axios';
import PledgeFactory from 'factories/pledges';

const FACTUARY_URL = '/factuary/api/v1';

/**
 * Service that will request pledges from the factuary api.
 *
 * @param {number} author - filter insights with the id of an author
 * @param {string} query - filter fields by an arbitrary string
 * @param {string} status - filter insights by publication status
 * @return {Promise}
 */
export const fetchPledges = (author, query, status = 'draft') => {
  const params = new URLSearchParams();
  if (author) params.append('author', author);
  if (query) params.append('query', query);
  if (status) params.append('status', status);

  return authorizedAxios.get(`${FACTUARY_URL}/pledges?${params}`).then(response => ({
    ...response,
    data: {
      ...response.data,
      pledges: response.data.pledges.map(PledgeFactory.toModel)
    }
  }));
};

/**
 * Service that will request authors of pledges from the factuary api
 */
export const fetchAuthors = () => {
  return authorizedAxios.get(`${FACTUARY_URL}/pledges/authors`);
};

/**
 * Service that will request a single pledge from the factuary api
 *
 * @param {string} id - the id of the pledge you wish to load
 * @return {Promise}
 */
export const fetchPledge = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/pledges/${id}`).then(response => ({
    ...response,
    data: PledgeFactory.toModel(response.data)
  }));
};

/**
 * Service that will request default pledge strings from factuary api
 *
 * @param {string} type - filter default strings by pledge type
 * @return {Promise}
 */
export const fetchDefaultStrings = type => {
  const filter = type ? `?type=${type}` : '';
  return authorizedAxios.get(`${FACTUARY_URL}/pledges/strings/defaults${filter}`);
};

/**
 * Persist an existing pledge to update it's data
 */
export const updatePledge = pledge => {
  return authorizedAxios.put(`${FACTUARY_URL}/pledges/${pledge.id}`, PledgeFactory.toDTO(pledge));
};

/**
 * Persist a new pledge to update it's data
 */
export const createPledge = pledge => {
  return authorizedAxios.post(`${FACTUARY_URL}/pledges`, PledgeFactory.toDTO(pledge));
};

export const getLanguageFile = (pledgeId, languageCode) => {
  return authorizedAxios.get(`/factuary/api/v2/strings/pledge/csv?content_id=${pledgeId}&lang=${languageCode}`);
};

export const getLanguageFileTemplate = (pledgeId) => {
  return authorizedAxios.get(`/factuary/api/v2/strings/pledge/csv?content_id=${pledgeId}`)
    .then((response) => response)
    .catch(err => '');
};

export const postLanguageFile = (languageFile, pledgeId, languageCode) => {
  return authorizedAxios.post(`/factuary/api/v2/strings/pledge/csv?content_id=${pledgeId}&lang=${languageCode}`, languageFile);
};
