import React from 'react';
import { Grid, Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { CHECK_BOX_MARGIN, TENANT_NAME_MARGIN, TENANT_INCLUDE, TENANT_EXCLUDE, TENNANTS_MESSAGE } from '../../../data/tenants'

const TenantsField = ({ tenantList = [], initialValue = [], handleTenantCheck, classes }) => {

  const renderCondition = (tenant, index) => {
    return (
      <RadioGroup row onChange={handleTenantCheck(index)}>
        <Grid container>
          <Grid item xs={9} sm={3}>
            <Typography variant="subtitle2" gutterBottom style={{ marginTop: TENANT_NAME_MARGIN }}>
              {tenant.name}
            </Typography>
          </Grid>
          <Grid item xs={9} sm={3}>
            <FormControlLabel checked={!initialValue.includes(tenant.id)} value={TENANT_INCLUDE} control={<Radio />} style={{ marginLeft: CHECK_BOX_MARGIN }} />
          </Grid>
          <Grid item xs={9} sm={3}>
            <FormControlLabel checked={initialValue.includes(tenant.id)} value={TENANT_EXCLUDE} control={<Radio />} style={{ marginLeft: CHECK_BOX_MARGIN }} />
          </Grid>
        </Grid>
      </RadioGroup>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom >
        {'Tenants'}
        <Tooltip title={TENNANTS_MESSAGE} placement="bottom-start">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Typography>

      <Typography variant="body1" gutterBottom>
        {"By default, all tenants are included. Use the buttons below to include "}
        {"or exclude specific tenants for this content's audience."}
      </Typography>
      <Grid container>
        <Grid item xs={9} sm={3}></Grid>
        <Grid item xs={9} sm={3}>
          <Typography variant="subtitle2" gutterBottom >
            Include
          </Typography>
        </Grid>
        <Grid item xs={9} sm={3}>
          <Typography variant="subtitle2" gutterBottom >
            Exclude
          </Typography>
        </Grid>
      </Grid>
      {tenantList.map(renderCondition)}
    </>
  );
};

export default TenantsField;
