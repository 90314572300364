import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import SurveyForm from 'components/views/surveys/form';
import useStyles from './EditSurvey.styles';
import { tenantHandler } from 'services/shared/handlers'

const flagsValues = {
  'condition_day': { unnecessaryFields: [] },
  'rules_based': { unnecessaryFields: ['dayCondition'] }
}

const EditSurvey = ({ actions, survey, surveyId, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  useEffect(() => {
    actions.onMount();
    actions.fetchSurvey(surveyId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(survey.survey)) setForm(survey.survey);
  }, [survey]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    const { unnecessaryFields } = flagsValues[form.flags];
    unnecessaryFields.forEach(field => handleChange(field)({ target: { value: '' } }));
    actions.updateSurvey(form);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages]
    });
  };

  const { testPassed, testResults } = survey;

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit survey</Typography>
        <div className={classes.testResultContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {isEmpty(form) || survey.isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <SurveyForm
          handleChange={handleChange}
          form={form}
          onSubmit={handleSubmit}
          handleFileDownload={handleFileDownload}
          handleTemplateFileDownload={handleTemplateFileDownload}
          handleFileUpload={handleFileUpload}
          handleStatusChange={handleStatusChange}
          tenants={tenants}
          handleTenantCheck={handleTenantCheck}
        />
      )}
    </div>
  );
};

export default EditSurvey;
