import React from 'react';
import { Box, Grid, TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Select from 'components/commons/select';
import TitleInput from 'components/views/surveys/title-input';
import MultiQuestionForm from 'components/views/surveys/form/MultiQuestionForm';
import {
  SURVEY_TYPES,
  SURVEY_STATE,
  SURVEY_GENDER_CONDITIONS,
  SURVEY_TRIGGER_WAYS
} from 'data/surveys';
import { LANGUAGES } from '../../../../data/shared';

const languageOptions = Object.keys(LANGUAGES).map((item) => {
  return { value: item, text: LANGUAGES[item].name };
});

const GeneralForm = ({ form, handleChange }) => {
  const SurveyTypeFormComponent = (() => {
    if (form.type === '8') return null;
    return <MultiQuestionForm onChange={handleChange} form={form} />;
  })();
  return (
    <Box p={4}>
      <TitleInput value={form.name} onChange={handleChange('name')} required />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Priority"
            type="text"
            value={form.priority}
            onChange={handleChange('priority')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value between 0 and 200." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Survey type"
            onChange={handleChange('type')}
            value={form.type}
            options={SURVEY_TYPES}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Language"
            id="lang"
            onChange={(e) => handleChange('lang')(e)}
            value={form.lang}
            options={languageOptions}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="ttl"
            label="Time To Live (TTL)"
            type="text"
            value={form.ttl}
            onChange={handleChange('ttl')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days the surveys should be displayed."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="cooldown"
            label="Cooldown"
            type="text"
            value={form.cooldown}
            onChange={handleChange('cooldown')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days between showing the surveys for the first time, and showing it again."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            label="Survey status"
            onChange={handleChange('state')}
            value={form.state}
            options={SURVEY_STATE}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label="Gender conditions"
            onChange={handleChange('genderCondition')}
            value={form.genderCondition}
            options={SURVEY_GENDER_CONDITIONS}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            label="Trigger way"
            onChange={handleChange('flags')}
            value={form.flags}
            options={SURVEY_TRIGGER_WAYS}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        {form.flags === 'condition_day' && (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Day condition"
              type="text"
              value={form.dayCondition}
              onChange={handleChange('dayCondition')}
              margin="normal"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      {!!form.type && SurveyTypeFormComponent}
    </Box>
  );
};

export default GeneralForm;
