import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSurvey, resetSurvey } from 'actions/survey';
import { CreateSurvey } from 'views';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';

const mapStateToProps = state => ({
  tenants: state.tenants
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createSurvey
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchTenantsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetSurvey());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSurvey);
