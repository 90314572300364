import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import Button from 'components/commons/button';
import Select from 'components/commons/select';
import Form from 'components/commons/form';
import TranslationsTab from '../tabs/Translations';
import { SettingsTab, ParametersTab, StringsTab, ConditionsTab } from '../tabs';
import { PLEDGE_CATEGORIES, PLEDGE_TYPES, PLEDGE_STATUSES } from 'data/pledges';
import useStyles from './Form.styles';
import { LANGUAGES } from '../../../../data/shared';


const tabs = [SettingsTab, ParametersTab, StringsTab, ConditionsTab, TranslationsTab];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    <Box p={3}>{children}</Box>
  </Typography>
);

const languageOptions = Object.keys(LANGUAGES).map((item) => {
  return { value: item, text: LANGUAGES[item].name };
});

const PledgeForm = ({ facts, form, handleChange, handleCheck, handleTenantCheck, handleFileDownload, handleTemplateFileDownload, handleFileUpload, handleStatusChange, labels, tenants, onSubmit }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  return (
    <Form className={classes.form} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Select
            label="Category"
            id="category"
            onChange={handleChange('category')}
            value={form.category}
            options={PLEDGE_CATEGORIES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Type"
            id="type"
            onChange={handleChange('type')}
            value={form.type}
            options={PLEDGE_TYPES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Status"
            id="status"
            onChange={handleChange('status')}
            value={form.status}
            options={PLEDGE_STATUSES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Language"
            id="lang"
            onChange={(e) => handleChange('lang')(e)}
            value={form.lang}
            options={languageOptions}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            label="Name"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="priority"
            label="Priority"
            type="text"
            value={form.priority}
            onChange={handleChange('priority')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value between 0 and 200." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="ttl"
            label="Time To Live (TTL)"
            type="text"
            value={form.ttl}
            onChange={handleChange('ttl')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days the pledge should be displayed."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="cooldown"
            label="Cooldown"
            type="text"
            value={form.cooldown}
            onChange={handleChange('cooldown')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days between showing the pledge for the first time, and showing it again."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Title"
            type="text"
            value={form.headerText}
            onChange={handleChange('headerText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Body"
            type="text"
            value={form.bodyText}
            onChange={handleChange('bodyText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Accept"
            type="text"
            value={form.acceptText}
            onChange={handleChange('acceptText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Decline"
            type="text"
            value={form.declineText}
            onChange={handleChange('declineText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" type="submit" size="medium">
        Save
      </Button>
      <AppBar elevation={0} position="static" className={classes.tabs}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Settings" {...a11yProps(0)} />
          <Tab label="Parameters" {...a11yProps(1)} />
          <Tab label="Strings" {...a11yProps(2)} />
          <Tab label="Conditions" {...a11yProps(3)} />
          <Tab label="Translations" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      {tabs.map((TabComponent, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <TabComponent
            classes={classes}
            form={form}
            handleChange={handleChange}
            handleCheck={handleCheck}
            handleTenantCheck={handleTenantCheck}
            handleFileDownload={handleFileDownload}
            handleTemplateFileDownload={handleTemplateFileDownload}
            handleFileUpload={handleFileUpload}
            handleStatusChange={handleStatusChange}
            facts={facts.facts}
            labels={labels.labels}
            tenants={tenants.tenants}
          />
        </TabPanel>
      ))}
    </Form>
  );
};

PledgeForm.propTypes = {
  facts: PropTypes.object,
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleCheck: PropTypes.func,
  handleTenantCheck: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleTemplateFileDownload: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleStatusChange: PropTypes.func,
  labels: PropTypes.object,
  tenants: PropTypes.object,
  onSubmit: PropTypes.func
};

export default PledgeForm;
