import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import PledgeForm from 'components/views/pledges/form';

import useStyles from './EditPledge.styles';
import { tenantHandler } from 'services/shared/handlers'

const EditPledge = ({ actions, facts, pledge, pledgeId, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  useEffect(() => {
    actions.onMount();
    actions.fetchPledge(pledgeId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isEmpty(pledge.pledge)) setForm(pledge.pledge);
  }, [pledge.pledge]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleCheck = name => ({ target: { checked } }) => {
    setForm({
      ...form,
      [name]: checked
    });
  };

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    actions.updatePledge(form).catch(console.log);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages]
    });
  };

  const { testPassed, testResults } = pledge;

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Pledge</Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {isEmpty(form) || pledge.isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <PledgeForm
          facts={facts}
          tenants={tenants}
          form={form}
          handleChange={handleChange}
          handleCheck={handleCheck}
          handleTenantCheck={handleTenantCheck}
          handleFileDownload={handleFileDownload}
          handleTemplateFileDownload={handleTemplateFileDownload}
          handleFileUpload={handleFileUpload}
          handleStatusChange={handleStatusChange}
          labels={labels}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default EditPledge;
