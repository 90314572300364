// Action types
export const TYPE_ADD_COLUMN = 'insight/ADD_INSIGHT_COLUMN';
export const TYPE_REMOVE_COLUMN = 'insight/REMOVE_INSIGHT_COLUMN';
export const TYPE_LOAD_COLUMNS = 'insight/LOAD_INSIGHT_COLUMNS';
export const TYPE_PERSIST_COLUMNS = 'insight/PERSIST_INSIGHT_COLUMNS';
export const TYPE_REQUEST_INSIGHT = 'insight/REQUEST_INSIGHT';
export const TYPE_RECEIVE_INSIGHT = 'insight/RECEIVE_INSIGHT';
export const TYPE_RECEIVE_INSIGHT_ERROR = 'insight/RECEIVE_INSIGHT_ERROR';
export const TYPE_REQUEST_INSIGHT_DEFAULTS = 'insight/REQUEST_INSIGHT_DEFAULTS';
export const TYPE_RECEIVE_INSIGHT_DEFAULTS = 'insight/RECEIVE_INSIGHT_DEFAULTS';
export const TYPE_RECEIVE_INSIGHT_DEFAULTS_CACHED = 'insight/RECEIVE_INSIGHT_DEFAULTS_CACHED';
export const TYPE_RECEIVE_INSIGHT_DEFAULTS_ERROR = 'insight/RECEIVE_INSIGHT_DEFAULTS_ERROR';
export const TYPE_REQUEST_INSIGHT_TEST = 'insight/REQUEST_INSIGHT_TEST';
export const TYPE_RECEIVE_INSIGHT_TEST = 'insight/RECEIVE_INSIGHT_TEST';
export const TYPE_RECEIVE_INSIGHT_TEST_ERROR = 'insight/RECEIVE_INSIGHT_TEST_ERROR';
export const TYPE_UPDATE_INSIGHT = 'insight/UPDATE_INSIGHT';
export const TYPE_UPDATE_INSIGHT_SUCCESS = 'insight/UPDATE_INSIGHT_SUCCESS';
export const TYPE_UPDATE_INSIGHT_ERROR = 'insight/UPDATE_INSIGHT_ERROR';
export const TYPE_CREATE_INSIGHT = 'insight/CREATE_INSIGHT';
export const TYPE_CREATE_INSIGHT_SUCCESS = 'insight/CREATE_INSIGHT_SUCCESS';
export const TYPE_CREATE_INSIGHT_ERROR = 'insight/CREATE_INSIGHT_ERROR';
export const TYPE_COPY_INSIGHT = 'insight/COPY_INSIGHT';
export const TYPE_RESET_INSIGHT = 'insight/RESET_INSIGHT';
export const TYPE_REQUEST_LANGUAGE_FILE = 'insight/REQUEST_LANGUAGE_FILE';
export const TYPE_POST_LANGUAGE_FILE = 'insight/POST_LANGUAGE_FILE';
export const TYPE_POST_LANGUAGE_FILE_SUCCESS = 'insight/POST_LANGUAGE_FILE_SUCCESS';
export const TYPE_POST_LANGUAGE_FILE_ERROR = 'insight/POST_LANGUAGE_FILE_ERROR';

export const TYPE_UPDATE_COLUMN_FILTERS = 'insights/UPDATE_COLUMN_FILTERS';
export const TYPE_REQUEST_INSIGHTS = 'insights/REQUEST_INSIGHTS';
export const TYPE_RECEIVE_INSIGHTS = 'insights/RECEIVE_INSIGHTS';
export const TYPE_RECEIVE_INSIGHTS_ERROR = 'insights/RECEIVE_INSIGHTS_ERROR';
export const TYPE_REQUEST_COLUMN_INSIGHTS = 'insight/REQUEST_COLUMN_INSIGHTS';
export const TYPE_RECEIVE_COLUMN_INSIGHTS = 'insight/RECEIVE_COLUMN_INSIGHTS';
export const TYPE_RECEIVE_COLUMN_INSIGHTS_ERROR = 'insight/RECEIVE_COLUMN_INSIGHTS_ERROR';

export const HEADER_MAX_LENGTH = 22;
export const BODY_MAX_LENGTH = 200;

export const INSIGHT_STATUSES = [
  { value: '0', text: 'Draft (Default)' },
  { value: '20', text: 'Live' },
  { value: '40', text: 'Deactivated' }
];

export const INSIGHT_PRIORITIES = [
  { value: '10', text: 'Normal' },
  { value: '20', text: 'Important' },
  { value: '30', text: 'Important 1' },
  { value: '40', text: 'Important 2' },
  { value: '60', text: 'Important 3' }
];

export const INSIGHT_TYPE = [
  { value: '0', text: 'Short insight' },
  { value: '1', text: 'Long insight' }
];

export const INSIGHT_CATEGORIES = [
  { value: '0', text: 'System' },
  { value: '1', text: 'Move' },
  { value: '2', text: 'Sleep' },
  { value: '3', text: 'Meal' },
  { value: '4', text: 'Mood' },
  { value: '5', text: 'Heart' }
];

export const INSIGHT_CLIENT_TYPES = [
  { value: 'none', text: 'All' },
  { value: 'web', text: 'Web' },
  { value: 'android', text: 'Android' },
  { value: 'ios', text: 'iOS' }
];

export const INSIGHT_LINK_SOURCES = {
  none: 0,
  external: 1,
  internal: 2
};
export const INSIGHT_LINK_SOURCE_OPTIONS = [
  { value: INSIGHT_LINK_SOURCES.none, text: 'None' },
  { value: INSIGHT_LINK_SOURCES.external, text: 'External Link' },
  { value: INSIGHT_LINK_SOURCES.internal, text: 'Internal App Link' }
];
