import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchInsight, updateInsight, resetInsight, getLanguageFile, getLanguageFileTemplate, saveLanguageFile } from 'actions/insight';
import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchFactsIfNeeded } from 'actions/facts';
import * as insightsService from 'services/insights';

import { EditInsight } from 'views';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';

const mapStateToProps = (state, ownProps) => ({
  facts: state.facts,
  insight: state.insight,
  labels: state.labels,
  tenants: state.tenants
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        fetchInsight,
        updateInsight,
        getLanguageFile,
        getLanguageFileTemplate,
        saveLanguageFile,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(fetchFactsIfNeeded());
      dispatch(fetchTenantsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetInsight());
    }
  },
  services: {
    fetchAuthors: insightsService.fetchAuthors
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  insightId: ownProps.match.params.id
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditInsight);
