import { stringToNumber, numberToString } from 'utils/number';
import { idGenerator } from 'utils/generator';

export default class SurveyFactory {
  static toDTO(model) {
    return {
      id: model.id || undefined,
      type: parseInt(model.type),
      state: parseInt(model.state),
      priority: parseInt(model.priority),
      cooldown: parseInt(model.cooldown),
      ttl: parseInt(model.ttl),
      name: model.name,
      intro_header: model.introHeader,
      intro_body: model.introBody,
      outro_header: model.outroHeader,
      outro_body: model.outroBody,
      questions: model.questions.map(SurveyFactory.questionToDTO),
      conditions: model.conditions,
      tests: model.tests,
      condition_day: model.dayCondition || undefined,
      condition_gender: model.genderCondition,
      flags: [model.flags],
      tenants: model.tenants,
      exclude_tenants: model.excludeTenants,
      available_langs: model.availableLangs,
      enable_langs: model.enableLangs,
      lang: model.lang
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      type: dto.type.toString(),
      state: dto.state.toString(),
      priority: dto.priority?.toString() ?? '',
      cooldown: dto.cooldown?.toString() ?? '',
      ttl: dto.ttl?.toString() ?? '',
      name: dto.name,
      introHeader: dto.intro_header,
      introBody: dto.intro_body,
      outroHeader: dto.outro_header,
      outroBody: dto.outro_body,
      questions: dto.questions?.map(SurveyFactory.questionToModel) ?? [],
      conditions: dto.conditions,
      tests: dto.tests,
      dayCondition: dto.condition_day ?? '',
      genderCondition: dto.condition_gender,
      flags: dto.flags[0],
      tenants: dto.tenants,
      excludeTenants: dto.exclude_tenants,
      availableLangs: dto.available_langs,
      enableLangs: dto.enable_langs,
      lang: dto.lang ?? ''
    };
  }

  static questionToDTO(questionModel) {
    return {
      id: questionModel.id ?? undefined,
      name: questionModel.name,
      type: parseInt(questionModel.type),
      text: questionModel.text,
      position: parseInt(questionModel.position) - 1,
      default_next_question: questionModel.defaultNextQuestion,
      display_conditions: questionModel.displayConditions.map(SurveyFactory.displayConditionToDTO),
      options: questionModel.options.map(SurveyFactory.optionToDTO),
    };
  }

  static questionToModel(questionDto) {
    return {
      id: questionDto.id,
      internalId: idGenerator.next().value,
      name: questionDto.name,
      type: questionDto.type.toString(),
      text: questionDto.text,
      position: parseInt(questionDto.position) + 1,
      defaultNextQuestion: questionDto.default_next_question,
      displayConditions:
        questionDto.display_conditions?.map(SurveyFactory.displayConditionToModel) ?? [],
      options: questionDto.options?.map(SurveyFactory.optionToModel) ?? [],
    };
  }

  static optionToDTO(optionModel) {
    return {
      id: optionModel.id ?? undefined,
      text: optionModel.text ?? null,
      value: stringToNumber(optionModel.value),
      input_type: optionModel.inputType ?? null,
      input_min: stringToNumber(optionModel.inputMin),
      input_max: stringToNumber(optionModel.inputMax),
      input_increment: stringToNumber(optionModel.inputIncrement),
      next_question: optionModel.nextQuestion ?? null,
      position: parseInt(optionModel.position),
    };
  }

  static optionToModel(optionDto) {
    return {
      id: optionDto.id,
      text: optionDto.text ?? '',
      value: numberToString(optionDto.value),
      inputType: optionDto.input_type ?? '',
      inputMin: numberToString(optionDto.input_min),
      inputMax: numberToString(optionDto.input_max),
      inputIncrement: numberToString(optionDto.input_increment),
      nextQuestion: optionDto.next_question ?? '',
      position: parseInt(optionDto.position),
    };
  }

  static displayConditionToDTO(displayConditionModel) {
    return {
      question_name: displayConditionModel.questionName ?? null,
      answer_indices:
        displayConditionModel.answerIndices
          .split(', ')
          .map((ind) => parseInt(ind) - 1)
          .filter((ind) => !isNaN(ind)) ?? null,
    };
  }

  static displayConditionToModel(displayConditionDto) {
    return {
      questionName: displayConditionDto.question_name ?? '',
      answerIndices: displayConditionDto.answer_indices
        .map((ind) => parseInt(ind) + 1)
        .filter((ind) => !isNaN(ind))
        .join(', '),
    };
  }
}
